<template>
  <div>
    <div class="page-title-area padding-50-pr">
      <div class="container">
        <div class="page-title-content">
          <h2>Donor Privacy Policy</h2>
        </div>
      </div>
    </div>

    <div class="about-section ptb-100">
      <div class="container">
        <div style="text-align: justify;">
          <span style="font-size: 18px;">
            PeaceJam Foundation is committed to respecting the privacy of our donors.

            <br><br>
            <h4 class="mt-0 mb-3">Information Collected</h4>

            Here are the types of donor information that we collect and maintain:

            <ul>
              <li>Contact information: name, organization/company, complete address, phone number, email address</li>
              <li>Donation information: amounts donated, date of donation(s), method of donation, and any comments regarding your donation (credit card number information is not retained)</li>
              <li>Non-personally identifiable information, such as how many hits our website page receives, and if the visitor is repeat or new. This requires that a ‘cookie’ be sent to your web browser.</li>
            </ul>

            <br><br>

            <h4 class="mt-0 mb-3">How Information is Used</h4>

            PeaceJam Foundation uses your data to maintain records of donations, send acknowledgements, mail year-end tax statements to you, send newsletters, direct mail pieces, and for internal marketing purposes.

            <br>

            <ul>
              <li>We add you to our mailing list, unless you request otherwise. We treat the lists with confidentiality and we do not share this list with any outside entities.</li>
              <li>We do not list individuals’ names in any of our publications, and would seek your permission before doing so. Corporate and Foundation sponsors may be listed in our Annual Report and event programs.</li>
              <li>Credit card numbers are NOT retained once processed. Our website has a SSL certificate</li>
              <li>We use website statistics to analyze our website usage. If you create a personal account at peacejam.org or billionacts.org, that information is used to personalize your internet experience.</li>
            </ul>

            <br><br>

            <b>No Sharing of Personal Information.</b> PeaceJam Foundation will not sell, rent, trade or
transfer your personal information to outside organizations. Use of donor information will be
limited to the internal purposes of PeaceJam Foundation and only to further the mission of
PeaceJam Foundation. Nevertheless, security measures may not prevent all losses, and CFS is
not responsible for any damages or liabilities relating to security failures.

            <br><br>

            <b>Removing Your Name From Our Mailing List</b><br>
Please contact us if you wish to be removed from our mailing list. Email info@peacejam.org or
call us at (303)455-2099.

            <br><br><br>

            Updated 10/14/2020
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DonorPrivacy"
  }
</script>

<style scoped>

</style>
